import './app.css'
import $ from 'jquery'
import LocomotiveScroll from 'locomotive-scroll';
import gsap from 'gsap'
import { ScrollSmoother } from 'gsap/ScrollSmoother.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import SplitText from "gsap/dist/SplitText";
import EN_headerHTML from './_header_en.html'
import EN_footerHTML from './_footer_en.html'
import AR_headerHTML from './_header_ar.html'
import AR_footerHTML from './_footer_ar.html'
let page = $('body').attr('id'),
	lastWindowWidth = 0,
	sizes = {
		width: window.innerWidth,
		height: window.innerHeight
	},
	scroll,
	asideScroll,
	anim,
	ST,
	scrollVal = 0,
	dir = 'down',
	selectedTL,
	Flickity,
	FlickityTransformer,
	$filtersCarousel,
	$fCarousel = [],
	canFilter = true,
	forceClick = false,
	current,
	lazy = $('.lazy'),
	isReady = false,
	isColsFlickity = false,
	isSearch = false,
	results = false,
	introCarousel,
	splitWords = [],
	splitLines = [],
	splitInnerLines = [],
	smoother,
	vh,
	vhS,
	isMobile;

const canvas = document.createElement('canvas'),
	preview = document.createElement("canvas"),
	ctx = canvas.getContext("2d");
	canvas.width = 1;
	canvas.height = 1;
	preview.width = 400;
	preview.height = 400;

let pageLang = $('html').attr('lang'),
	pageName = $('body').attr('data-name'),
	header = $('header'),
	footer = $('.footer_container');

(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? isMobile = true : isMobile = false
var userAgent = navigator.userAgent || navigator.vendor || window.opera;

// Windows Phone must come first because its UA also contains "Android"
if (/windows phone/i.test(userAgent)) {
	$('body').addClass('android')
}

if (/android/i.test(userAgent)) {
	$('body').addClass('android')
}

// iOS detection from: http://stackoverflow.com/a/9039885/177710
if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
	$('body').addClass('ios')
}

gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollSmoother)
gsap.config({ nullTargetWarn: false })

async function buildPages() {

	if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
		$('body').addClass('isSafari')
	}

	if (isMobile == true) {

		$('body').addClass('isMobile')

	} else {

		$('body').addClass('isDesktop')

	}

	if(pageLang == 'en') {
		header.html(EN_headerHTML)
		footer.html(EN_footerHTML)
	} else {
		header.html(AR_headerHTML)
		footer.html(AR_footerHTML)
	}

	if(pageName) { $('#getPageName').text(pageName)}

	const css = await import('./'+pageLang+'.css')

	appendImgs()

	if(page != 'farah') {
		buildScroll()
	}

	document.fonts.ready.then(function() {

		onload()

	})

}

$.fn.isInViewport = function() {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
	return elementBottom > viewportTop && elementTop < viewportBottom;
}

$(window).on('load', function(){

	$.ready.then(function(){

		$('body').addClass('progress');

		Flickity = require('flickity')
		FlickityTransformer = require('flickity-transformer')

		buildPages()

	})

})

function onload(){

	onWindowResize()

	window.addEventListener( 'resize', onWindowResize );
	window.addEventListener( 'orientationchange', onOrientationChange);

	globalFunction()

	fire()

}

function fire(){

	$('body').removeAttr('style')

	let fireTL = gsap.timeline();

	fireTL

	.to('#site', 0.5, {autoAlpha: 1})

	.call(function(){

		startScroll(scroll)

		if(page == 'farah') {

			smoother = ScrollSmoother.create({
				wrapper: '#scroller',
				content: '#scroller > div',
				smooth: 0.5,
				ignoreMobileResize: true,
				effects: true,
				smoothTouch: false,
			})

			window.addEventListener('scroll', pageScroll)

			window.scroll(0, 0)

			smoother.scrollTop(0, false)

		}

		pageScroll(0)

		setTimeout(function(){

			isReady = true

		}, 500);
	})
}

function onWindowResize(e, value) {

	sizes.width = window.innerWidth
	sizes.height = window.innerHeight
	vh = sizes.height * 0.01;
	vhS = sizes.height * 0.01;

	if(isMobile) {

		if(sizes.width != lastWindowWidth) {

			setH();

			$('body').addClass('progress');

		}

	} else {

		setH();

		$('body').addClass('progress');

	}

	document.documentElement.style.setProperty('--vhS', `${vhS}px`);

	function setH(){

		document.documentElement.style.setProperty('--vh', `${vh}px`);

		if(isReady == true) {
			splitWords.forEach(function(e,i){ if(e) { e.revert() } })
			splitLines.forEach(function(e,i){ if(e) { e.revert() } })
			splitInnerLines.forEach(function(e,i){ if(e) { e.revert() } })
		}

		clearTimeout(window.scrollUpdate);

		window.scrollUpdate = setTimeout(function(){

			if(scroll){scroll.update()};

		}, 500);

	}

	if(page == 'app') {
		createTrigger();
	}

	clearTimeout(window.resizedFinished);

	window.resizedFinished = setTimeout(function(){

		$('body').removeClass('progress')

		if(!isMobile) { setH(); }

		if(page == 'farah') {
			createTrigger2()
		}

	}, 500);

	lastWindowWidth = sizes.width

    if($('._tempCols').length != 0) {
    	introCols()
    }

}

function onOrientationChange(){

	clearTimeout(window.resizedFinished);

	window.resizedFinished = setTimeout(function(){

		onWindowResize();

	}, 250);

}

function appendImgs(){

	var appendBGs = $(document).find('.load_bg'),
		appendVID = $('body').find('.load_video'),
		iMGs = $(document).find('.load_img');


	appendVID.each(function(i){

		var t = $(this),
			s = t.attr('data-src');

		t.append('<video preload="metadata" autoplay loop playsinline muted><source src="'+s+'" type="video/mp4"></video>')

		gsap.to(t.find('video'), 0.5, {autoAlpha: 1, delay: 0.5})

	});

	iMGs.each(function(i){

		var t = $(this),
			s = t.attr('data-src');

		t.removeAttr('data-src').attr("src", support_format_webp(s)).removeClass('load_img');

		t.removeClass('load_img')

		$('<img src="'+ support_format_webp(s) +'">').on('load',function(){

			clearTimeout(window.reposition);

			window.reposition = setTimeout(function(){

				if(scroll){scroll.update()};

			}, 500)

		})

	});

    appendBGs.each(function(i){

		var t = $(this),
			s = t.attr('data-src');

		t.removeAttr('data-src').css({ 'background-image': 'url('+ support_format_webp(s) +')' }).removeClass('load_bg')

		$('<img src="'+ support_format_webp(s) +'">').on('load',function(){

			clearTimeout(window.reposition);

			window.reposition = setTimeout(function(){

				if(scroll){scroll.update()};

			}, 500)

		})

    });

	lazybuild()

}

function lazyLoad(element, src){

	gsap.set(element.find('.spinner'), {autoAlpha: 1, ease: 'power3.out'});

	element.removeClass('lazy').css({ 'background-image': 'url('+ support_format_webp(src) +')' });

	$('<img src="'+ support_format_webp(src) +'">').on('load', function() {

		element.find('.spinner').addClass('pause');

		gsap.to(element.find('.spinner'), 0.5, {autoAlpha: 0, ease: 'power3.out', onComplete: function(){
			element.addClass('ready')
			element.find('.spinner').remove()
		}})

		gsap.to(element.find('._temp'), 1, {autoAlpha: 0, ease: 'power3.out', delay: 0.5, onComplete: function(){
			element.find('._temp').remove()
		}})

	});

}

function support_format_webp(img) {

	// var elem = document.createElement('canvas')

	// if (!!(elem.getContext && elem.getContext('2d'))) { return img.substr(0, img.lastIndexOf(".")) + ".webp" } else { return img}

	return img
}

function buildScroll(){

	let asideScroller = document.querySelector('#asideScroller');

	scroll = new LocomotiveScroll(
	{
		el: document.querySelector('#scroller'),
		smooth: page == 'shop' ? false : true,
		getDirection: true,
		scrollFromAnywhere: false,
		tablet: {
			smooth:false,
			lerp: 0,
		},
		smartphone: {
			smooth:false,
			lerp: 0
		}
	})

	if(asideScroller) {

		asideScroll = new LocomotiveScroll(
		{
			el: asideScroller,
			smooth: true,
			scrollFromAnywhere: true,
			smoothClass: 'has-side-smooth',
			scrollbarClass: 'c-scrollbar2',
		    touchMultiplier: 2,
    		smoothMobile: 0,
			tablet: {
				smooth: true,
				lerp: 0.01,
			},
			smartphone: {
				smooth: true,
				lerp: 0.01,
			}
		})

		stopScroll(asideScroll)

	}

	stopScroll(scroll)

	scroll.on('scroll', (func, speed) => {

		if(page == 'app') {
			ScrollTrigger.update
		}

		if(isReady) {
			pageScroll(func);
		}

	})

}

function stopScroll(element){

	if(element) { element.stop() }

	if(element == scroll) {

		if(isMobile || sizes.width <= 640) {		
			$('body').addClass('hidden').css({ top: scrollVal * -1 });
			if(scroll){
				scroll.scrollTo(scrollVal, { duration: 0, disableLerp: true })
			}
		}

	}


}

function startScroll(element){

	if(element) { element.start() }

	if(element == scroll) {
		if(isMobile || sizes.width <= 640) {		
			$('body').removeClass('hidden').css({ top: 0 });
			if(scroll){
				scroll.scrollTo(scrollVal, { duration: 0, disableLerp: true })
			}
		}
	}


}

function pageScroll(val){

	if(val != 0 && page != 'farah') {
		scrollVal = val.scroll.y;
		dir = val.direction;
	}

	let eleWrap = $('._eleWrap');

	if( eleWrap.length != 0 ) {

		eleWrap.each(function(i){

			let $this = $(this),
				eleY = $this.find('._eleY'),
				eleX = $this.find('._eleX');

			if($this.isInViewport()) {
				animateEle($this, eleY, eleX)
			}

		})

	}

	let splitWrap = $('._splitWrap');

	if( splitWrap.length != 0 ) {

		splitWrap.each(function(i){

			let $this = $(this),
			getWords = $this.find('._splitWords'),
			getLines = $this.find('._splitLines'),
			getMask = $this.find('._splitMask');

			if($this.isInViewport()) {
				split($this, getMask, getWords, getLines, i)
			}

		})

	}

	if(lazy.length != 0) {

		lazy.each(function(){

			var $this = $(this),
			src = $this.attr('data-src');

			if(!$this.hasClass('inviewport')) {

				if($this.isInViewport() && !$this.hasClass('inview')) {

					$this.addClass('inview');

					lazyLoad($this, src)
				}

			} else {

				if(!$this.hasClass('inview')) {

					$this.addClass('inview');

					lazyLoad($this, src)

				}

			}

		})

	}

	let counters = $('.count');

	if(counters.length != 0) {

		counters.each(function () {

			let $this = $(this);

			if($this.isInViewport() && !$this.hasClass('inview') ) {

				$this.addClass('inview');

				gsap.set($this, {autoAlpha: 1})

				gsap.from($this, {
					textContent: 0,
					duration: 2,
					ease: "power3.out",
					snap: { textContent: 1 },
					stagger: {
						each: 1.0,
						onUpdate: function() {
							this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
						},
					}
				});

			}

		});

	}

	if($('.bar_set').length != 0) {

		$('.stores_row').each(function(i){

			if(canFilter) {

				let $this = $(this),
					getID;

				if(!forceClick) {

					if($this.offset().top <= sizes.height/2) {

						if(!$this.hasClass('inview')) {
							$this.addClass('inview')
						}

					} else {

						if($this.hasClass('inview')) {
							$this.removeClass('inview')
						}

					}

					current = $('.stores_row.inview:last');

					if(current.length != 0) {

						getID = current.find('.stores_head').attr('data-id');

						$(document).find('.filter').each(function(){
							let $this = $(this),
								id = $this.attr('data-id'),
								index = $(this).index();

							if(id != getID) {
								if($this.hasClass('active')) {
									$this.removeClass('active')
								}
							} else {
								if(!$this.hasClass('active')) {
									$this.addClass('active')
									$filtersCarousel.select(index)
								}
							}

						})
					}

				}
			}

		})

	}

	if($('.app_content_wrap').length != 0) {

		if($('.app_section').isInViewport() && !$('.faq_section').isInViewport()) {

			$('.app_content_wrap').each(function() {

				var $this = $(this);

				if($this.offset().top <= sizes.height/2) {

					if(!$this.hasClass('inview')) {

						$('.app_content_wrap').removeClass('inview')

						$this.addClass('inview')

					}

				}

			})

			let current = $('.app_content_wrap.inview'),
				color = current.find('.card_details').css('background-color');

			if(color) {

				$('.app_phone').css('color', color)

				$('body').css('background-color', color.replace(')', ', 0.6)').replace('rgb', 'rgba'))
			}

		} else {

			$('body').removeAttr('style')

			$('.app_phone').css('color', '#000000')

		}


	}

	let appCircle = $('.app_banner_circle');

	if( appCircle.length != 0 ) {

		if(appCircle.isInViewport() && !appCircle.hasClass('inview')) {
			appCircle.addClass('inview')
			gsap.to(appCircle, 0.8, {scale: 1, ease: 'power3.out'})
		}

		if($('.app_banner_hand').isInViewport() && !$('.app_banner_hand').hasClass('inview')) {
			$('.app_banner_hand').addClass('inview')
			gsap.to('.app_banner_hand', 1, {y: 0, ease: 'power3.out', delay: 0.3})
		}

	}

}


function numberWithCommas(x) {
	let val = x;
	return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function split($this, getMask, getWords, getLines, i) {

	if(!$this.hasClass('inview') ) {

		$this.addClass('inview');

		if(getMask.length != 0) {
			let getElement,
				getType;

			$(getMask).css({'padding-left': 0, 'padding-right': 0})
			getMask.hasClass('lines') ? getType = 'lines' : getType = 'words';

			splitLines[i] = new SplitText(getMask, {type:"lines"});
			splitInnerLines[i] = new SplitText(splitLines[i].lines, {type:getType, wordsClass:"SplitClass"});

			getMask.hasClass('lines') ? getElement = splitInnerLines[i].lines :  getElement = splitInnerLines[i].words

			gsap.set(getMask, {autoAlpha: 1})
			gsap.set(getElement, { y: '115%'})
			gsap.to(getElement, 0.8, { y: 0, autoAlpha: 1, ease: 'power3.out', stagger: getType == 'lines' ? 0.12 : 0.07 })
		}

		if(getWords.length != 0) {
			splitWords[i] = new SplitText(getWords, {type:"words", wordsClass:"SplitClass"});
			gsap.set(getWords, {autoAlpha: 1})
			gsap.set(splitWords[i].words, { y: 20, autoAlpha: 0})
			gsap.to(splitWords[i].words, 0.8, { y: 0, autoAlpha: 1, ease: 'power3.out', stagger: 0.1, onComplete: function(){
				splitWords[i].revert()
			} })
		}

		if(getLines.length != 0) {
			gsap.set(getLines, {autoAlpha: 1, delay: 0.8})
			splitLines[i] = new SplitText(getLines, {type:"lines", linesClass:"SplitClass"});
			gsap.set(splitLines[i].lines, { y: 20, autoAlpha: 0})
			gsap.to(splitLines[i].lines, 0.8, { y: 0, autoAlpha: 1, ease: 'power3.out', delay: 0.8, stagger: 0.1, onComplete: function(){
				splitLines[i].revert()
			} })
		}
	}
}

function animateEle($this, eleY, eleX) {

	if(!$this.hasClass('inview') ) {

		$this.addClass('inview');

		gsap.set($this, {autoAlpha: 1}, 0)

		if(eleY.length != 0) {
			gsap.set(eleY, { y: 100, autoAlpha: 0})
			gsap.to(eleY, 1, { y: 0, autoAlpha: 1, ease: 'power3.out', delay: 0.4, stagger: 0.15 })
		}

		if(eleX.length != 0) {

			gsap.set(eleX, { x: 100, autoAlpha: 0})
			gsap.to(eleX, 1, { x: 0, autoAlpha: 1, ease: 'power3.out', delay: 0.4, stagger: 0.15 })
		}

	}
}

function lazybuild(){

	lazy = $(document).find('.lazy')

	if(lazy.length != 0) {

		lazy.each(function(){

			var $this = $(this),
				s = $this.attr('data-src')

			if($this.find('._temp').length == 0 && !$this.hasClass('inview')) {

				$this.append('<div class="_temp full_bg" style="background-color: #000"></div>');

				if(!$this.hasClass('nospinner')) {
					$this.find('._temp').append('<div class="spinner" style="visibility: hidden;"></div>')
				} else {
					if(!$this.hasClass('dark')) {
						$this.find('._temp').css('background-color', '#ddd')
					}
				}

				// let image = new Image();

				// image.onload = function() {

				// 	preview.getContext("2d").drawImage(image, 0, 0, preview.width, preview.height)

				// 	ctx.drawImage(image, 0, 0, 1, 1);

				// 	let i = ctx.getImageData(0, 0, 1, 1).data;

				// 	var color = "#" + ((1 << 24) + (i[0] << 16) + (i[1] << 8) + i[2]).toString(16).slice(1);

				// 	$this.find('._temp').css('background-color', color)

				// }

				// image.src = support_format_webp(s);

			}

		})

	}
}

function interactionY(target){

	if(!isMobile) {

		$(document).find(target).each(function(i,element) {

			let tl = new gsap.timeline({paused:true}),
				$this = $(this),
				ele = $this.find('._ele');

			tl.to(ele, 0.3, { y: '-110%', ease: 'power3.in', stagger: 0.07 })

			.set(ele, { y: '110%' })

			.to(ele, 0.3, { y: '0%', ease: 'power3.out', stagger: 0.07 })

			element.animation = tl;

		})

		$(document).on('mouseenter', target, function(e){

			if(!$(this).hasClass('disabled')) {

				if(!this.animation.isActive()) {

					this.animation.restart();

				}
			}

		})
	}

}

function globalFunction(){

	$('h1,h2,h3,h4,h5,h6').each(function(){
		if(!$(this).hasClass('_splitMask')) {			
			$(this).css({'padding-left': 0, 'padding-right': 0})
		}
	})

	if(page) {

		$('li[data-id='+page+'] a, li[data-id='+page+'] span').addClass('active')

	}

	$('.ft_floating').click(function(e){
		scroll.scrollTo(0);
	})

	$('.scrollTo').click(function(e){

		var id =  $(this).attr('data-id'),
			target = '#' + id;

		e.preventDefault()

		scroll.scrollTo(target);

		return false;

	})

	if(!isMobile) {

		$('.dropdown_set').mouseenter(function(){
			if(!$(this).hasClass('opened')) {
				$(this).addClass('opened')
			}
		}).mouseleave(function(){
			if($(this).hasClass('opened')) {
				$(this).removeClass('opened')
			}
		})

		$('.dropdown_option').click(function(){
			var id = $(this).attr('data-id');

			document.getElementById("country").value = id

			$('#country').get(0).change();

		})

	}

	$('.dropdown select').change(function(){

		var url = $('#country').find(':selected').attr('data-url');

		location.href = url

	})


	let $menuCarousel = new Flickity('.ms_set', {
		prevNextButtons: false,
		accessibility: true,
		bounce: true,
		pageDots: false,
		percentPosition: true,
		selectedAttraction: 0.2,
		friction: 0.8,
		contain: true,
		cellAlign: pageLang == 'en' ? 'left' : 'right',
		rightToLeft: pageLang == 'ar' ? true : false,
	})

	flickFix($menuCarousel)

	let menuTL = new gsap.timeline({paused: true})

	menuTL

	.set('.mega_menu_wrap', {autoAlpha: 1})

	.call(function(){

		$('body').removeClass('progress')

	})

	.call(function(){

		$('body').addClass('progress')

		$('header').removeClass('opened')

	})

	.to('.mega_menu_wrap > i', 0.7, {scaleY: 1, ease: 'power3.inOut', onUpdate: function(){
		if(this.progress() >= 0.5 ) {
			$('header').addClass('opened')
		}
	}}, 0)

	.to('.mega_menu', 0.5, {autoAlpha: 1, ease: 'power3.inOut'}, 0)

	.from('.mega_menu ._element', 0.7, {y: '100%', autoAlpha: 0, ease: 'power3.out', stagger: 0.05}, 0.5)

	.call(function(){

		$('body').removeClass('progress')

	})

	$(document).on('click', 'li[data-id=shop]', function(e){

		let $this = $(this);

		if(!$this.hasClass('active')) {

			$this.addClass('active')

			menuTL.timeScale(1.1).play()

		} else {


			$this.removeClass('active')

			menuTL.timeScale(1.8).reverse()

		}

	})


	// Burger Menu
	let burgerTL = new gsap.timeline({paused: true})

	burgerTL

	.set('.burger_menu', {autoAlpha: 1})

	.call(function(){

		$('body').removeClass('progress')

	})

	.call(function(){

		$('body').addClass('progress')

		$('.burger_set').removeClass('colored')

	})

	.to('.burger_menu > i', 0.7, {scaleY: 1, ease: 'power3.inOut', onUpdate: function(){

		if(this.progress() >= 0.5 ) {

			$('.burger_set').addClass('colored')

		}

	}}, 0)

	.to($('.burger_set i').eq(0), 0.5, {rotate: 45, y: 6, ease: 'power3.inOut'}, 0)

	.to($('.burger_set i').eq(1), 0.5, {autoAlpha: 0, ease: 'power3.inOut'}, 0)

	.to($('.burger_set i').eq(2), 0.5, {rotate: -45, y: -6, ease: 'power3.inOut'}, 0)

	.from('.burger_content .ft_col', 0.7, {y: '-100%', autoAlpha: 0, ease: 'power3.out', stagger: -0.05}, 0.5)

	.call(function(){

		$('body').removeClass('progress')

	})

	$(document).on('click', '.burger_set', function(e){

		let $this = $(this);

		if(!$this.hasClass('active')) {

			$this.addClass('active')

			burgerTL.timeScale(1.1).play()

			stopScroll(scroll)

		} else {

			$this.removeClass('active')

			burgerTL.timeScale(1.8).reverse()

			startScroll(scroll)

		}

	})
	if($('.faq_head').length != 0) { FAQs() }
	if($('.app_content_wrap').length != 0) { appPage() }
	if($('#farah').length != 0) { farahPage() }
	if($('#deals').length != 0) { deals() }
	if($('.featured_set').length != 0) { featuredStores() }
	if($('.store').length != 0) { buildStores() }
	if($('.bar_set').length != 0) { createFilters() }
	if(page == 'shop') { storesPage() }

	interactionY('.small_button')
	interactionY('.copy')
}

function introCols() {

	if(sizes.width > 640 && sizes.width <= 1200){

		if ( isColsFlickity == false ) {

			build();

		}

	} else {

		if ( isColsFlickity == true ) {

			isColsFlickity = false;

			introCarousel.destroy();

			introCarousel = null

		}

	}

	function build(){

		isColsFlickity = true;

		introCarousel = new Flickity('._tempCols', {
			prevNextButtons: false,
			accessibility: true,
			bounce: true,
			pageDots: false,
			percentPosition: true,
			selectedAttraction: 0.2,
			friction: 0.8,
			contain: true,
			cellAlign: pageLang == 'en' ? 'left' : 'right',
			rightToLeft: pageLang == 'ar' ? true : false,
		})

		flickFix(introCarousel)

	}

}

function deals(){

	const $carousel = new Flickity('.deals_set', {
			prevNextButtons: false,
			accessibility: true,
			pageDots: false,
			bounce: false,
			percentPosition: true,
			initialIndex: isMobile ? 0 : 2,
			freeScroll: true,
		});

	const $transform = new FlickityTransformer($carousel,[
		{
			name: 'translateY',
			stops: [
				[-sizes.width, (0.035714285714286 * sizes.width)],
				[0, 0],
				[ sizes.width, (0.035714285714286 * sizes.width)],
			]
		},{
			name: 'rotate',
			stops: [
				[-sizes.width, (0.003571428571429 * -sizes.width)],
				[0, 0],
				[ sizes.width, (0.003571428571429 * sizes.width)],
			]
		}
	])

	flickFix($carousel)	

}

function buildStores(){

	let asideTL = new gsap.timeline({paused: true})

	asideTL

	.call(function(){

		$('body').removeClass('progress')

		startScroll(scroll)

	})

	.call(function(){

		$('body').addClass('progress')

	})

	.set('aside', {autoAlpha: 1}, 0)

	.to('#scroller', 0.5, {autoAlpha: 0.5, ease: 'power3.out'}, 0.3)

	.to('.c-scrollbar', 0.5, {autoAlpha: 0, ease: 'power3.out'}, 0.3)

	.to('.c-scrollbar2', 0.5, {autoAlpha: 1, ease: 'power3.out'}, 0.3)

	.to('.deal_cover i, .deals_side', 0.5, {x: 0, ease: 'power3.out'}, 0.3)

	.call(function(){

		stopScroll(scroll)

		$('body').removeClass('progress')

	})


	$('.store').each(function(){

		let $this = $(this),
			getTitle = $this.attr('data-title'),
			getCategory = $this.attr('data-category'),
			getValue = $this.attr('data-value'),
			getUnit = $this.attr('data-unit'),
			getCover = $this.attr('data-cover'),
			getPopup = $this.attr('data-popup'),
			getLogo = $this.attr('data-logo'),
			buttonLabel;

		if(!$this.hasClass('preload')) {

			pageLang == "en" ? buttonLabel = "Details" : buttonLabel = "اعرف أكثر";

			if(!getCategory || getCategory == '') { getCategory = '' }
			if(!getValue || getValue == '') { getValue = '' }
			if(!getUnit || getUnit == '') { getUnit = '' }


			$this.append('<article class="rounded-all-less full_bg"><div class="store_set rounded-all-less full_bg"><div class="overlay_cover store_cover flex x-center v-center"><i class="lazy cover full_bg" data-src="'+getCover+'"></i></div><div class="store_bar flex space-between v-center"><div class="store_bar_side flex v-center"><div class="store_logo"><i class="cover full_bg lazy" data-src="'+support_format_webp(getLogo)+'""></i></div><div class="store_name"><div class="label">'+getTitle+'</div><div class="cat">'+getCategory+'</div></div></div><div class="store_bar_side flex v-center"><div class="store_off flex flex-column"><div class="label">'+getValue+'</div><div class="micro">'+getUnit+'</div></div></div></div></div></article>')

			if(getPopup == 'true') {
				$this.find('.store_cover').append('<div class="small_button flex x-center v-center"><strong class="_ele micro uppercase">'+buttonLabel+'</strong></div>')
			} else {
				let getURL = $this.attr('data-url');
				$this.find('.store_set').wrap('<a href="'+getURL+'" refer="noopener" target="_blank"></a>')
				$this.find('.store_cover').append('<div class="circle_button flex v-center x-center"><svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.749079 1.434C0.731125 1.63149 0.802941 1.77512 0.910664 1.88285C1.03634 2.00852 1.19793 2.06239 1.35951 2.04443L5.57867 1.95466L0.425909 7.10743C0.300232 7.2331 0.24637 7.39469 0.24637 7.53832C0.24637 7.71786 0.318186 7.86149 0.425909 7.96921L1.00043 8.54374C1.12611 8.66942 1.26974 8.74123 1.43133 8.72328C1.59291 8.74123 1.73654 8.66942 1.86222 8.54374L7.01498 3.39097L6.92521 7.61014C6.92521 7.78967 6.97908 7.95126 7.0868 8.05898C7.21248 8.18466 7.35611 8.25648 7.53565 8.22057H8.32562C8.4872 8.23852 8.63083 8.16671 8.75651 8.04103C8.86423 7.93331 8.93605 7.78967 8.93605 7.61014V0.644029C8.93605 0.500398 8.88219 0.338813 8.75651 0.213136C8.64879 0.105413 8.4872 0.0515513 8.32562 0.0335974H1.35951C1.19793 0.0515513 1.03634 0.105413 0.928618 0.213136C0.802941 0.338813 0.749079 0.500398 0.749079 0.644029V1.434Z" fill="black"/></svg></div>')
			}
		} else {

			$this.append('<article class="rounded-all-less full_bg preload"><div class="store_set rounded-all-less full_bg"><div class="overlay_cover store_cover flex x-center v-center"></div><div class="store_bar flex space-between v-center"><div class="store_bar_side flex v-center"><div class="store_logo"></div><div class="store_name"><div class="label">STORE NAME</div><div class="cat">CAREGORY</div></div></div></div></div></article>')

		}

	})

	lazybuild()

	$(document).on('click', '.store', function(e){

		let $this = $(this),
			getTitle = $this.attr('data-title'),
			getCategory = $this.attr('data-category'),
			getDealTitle = $this.attr('data-deal-title'),
			getDealSub = $this.attr('data-deal-subtitle'),
			getDealCode = $this.attr('data-deal-code'),
			getDealDate = $this.attr('data-deal-date'),
			getURL = $this.attr('data-url'),
			getCover = $this.attr('data-cover'),
			getPopup = $this.attr('data-popup'),
			getLogo = $this.attr('data-logo');

		if(getPopup == 'true') {

			vhS = window.innerHeight * 0.01;

			document.documentElement.style.setProperty('--vhS', `${vhS}px`);

			asideScroll.update()

			startScroll(asideScroll)

			asideTL.play()

			if(getDealSub) {
				$('.ds_text p').show().text(getDealSub)
			} else {
				$('.ds_text p').hide()
			}

			if(getDealCode) {
				$('.promo_set').show()
				$('#copy').text(getDealCode)
			} else {
				$('.promo_set').hide()
			}

			if(getDealDate) {
				$('.ds_date').show()
				$('.ds_date strong').text(getDealDate)
			} else {
				$('.ds_date').hide()
			}

			if(getURL) {
				$('#getURL').show().find('p').attr('href', getURL)
			} else {
				$('#getURL').hide()
			}

			$('.ds_text h6').text(getDealTitle)

			$('.ds_title h5').text(getTitle)

			$('.ds_title p').text(getCategory)

			$('.deal_cover i').css({ 'background-image': 'url('+ support_format_webp(getCover) +')' })

			$('.ds_logo i').css({ 'background-image': 'url('+ support_format_webp(getLogo) +')' })

			asideScroll.update()

		}

	})

	$(document).on('click', '.deals_side', function(e){

		e.stopPropagation()

	})

	$(document).on('click', '.close_side, .deals_container', function(e){

		stopScroll(asideScroll)

		asideTL.reverse()

	})


	$(document).on('click', '.copy', function(e){

		let $this = $(this);

		var r = document.createRange();

		r.selectNode(document.getElementById('copy'));
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(r);

		try {

			document.execCommand('copy');

			window.getSelection().removeAllRanges();

			$this.addClass('disabled')

			clearTimeout(window.copy);

			window.copy = setTimeout(function(){

				$this.removeClass('disabled')

			}, 1000)

		} catch (err) {

			console.log('Unable to copy!');

		}

	})

}

function flickFix($carousel){

	$carousel.on('dragStart', function(){
		$carousel.slider.childNodes.forEach(slide => slide.style.pointerEvents = "none")
		// stopScroll(scroll)
	})

	$carousel.on('dragEnd', function(){
		$carousel.slider.childNodes.forEach(slide => slide.style.pointerEvents = "all")
		// startScroll(scroll)
	})

}

function featuredStores(){

	$('.featured_store').each(function(){

		let $this = $(this),
			getTitle = $this.find('a > span').text(),
			getDiscount = $this.attr('data-discount'),
			getCover = $this.attr('data-cover'),
			getLogo = $this.attr('data-logo');

		if(!$this.hasClass('preload')) {

			$this.find('a').append('<div class="overlay_cover featured_cover full_bg"><i class="lazy cover full_bg" data-src="'+getCover+'"></i></div><div class="featured_details full_bg flex v-center x-center"><div class="circle_button flex v-center x-center"><svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.749079 1.434C0.731125 1.63149 0.802941 1.77512 0.910664 1.88285C1.03634 2.00852 1.19793 2.06239 1.35951 2.04443L5.57867 1.95466L0.425909 7.10743C0.300232 7.2331 0.24637 7.39469 0.24637 7.53832C0.24637 7.71786 0.318186 7.86149 0.425909 7.96921L1.00043 8.54374C1.12611 8.66942 1.26974 8.74123 1.43133 8.72328C1.59291 8.74123 1.73654 8.66942 1.86222 8.54374L7.01498 3.39097L6.92521 7.61014C6.92521 7.78967 6.97908 7.95126 7.0868 8.05898C7.21248 8.18466 7.35611 8.25648 7.53565 8.22057H8.32562C8.4872 8.23852 8.63083 8.16671 8.75651 8.04103C8.86423 7.93331 8.93605 7.78967 8.93605 7.61014V0.644029C8.93605 0.500398 8.88219 0.338813 8.75651 0.213136C8.64879 0.105413 8.4872 0.0515513 8.32562 0.0335974H1.35951C1.19793 0.0515513 1.03634 0.105413 0.928618 0.213136C0.802941 0.338813 0.749079 0.500398 0.749079 0.644029V1.434Z" fill="black"/></svg></div><div class="featured_name"><p><strong>'+getTitle+'</strong></p></div><div class="featured_bar flex space-between"><div class="featured_logo"><i class="cover full_bg lazy" data-src="'+support_format_webp(getLogo)+'""></i></div><div class="featured_text"><p><strong>'+getDiscount+'</strong></p></div></div></div>')

		} else {

			$this.append('<div class="featured_details full_bg flex v-center x-center"><div class="featured_bar flex space-between"><div class="featured_logo"></div></div></div>')
		}

	})

	lazybuild()

	$('.featured_set').each(function(i){

		let $this = $(this),
			$imgs = $(this).find($('.featured_cover'))

		$fCarousel[i] = new Flickity($this[0], {
			prevNextButtons: false,
			accessibility: true,
			pageDots: false,
			bounce: false,
			percentPosition: true,
			cellAlign: pageLang == 'en' ? 'left' : 'right',
			rightToLeft: pageLang == 'ar' ? true : false,
			freeScroll: !isMobile && page != 'shop' ? true : false,
			contain: true,
			on: {
				ready: function() {

					setTimeout(function(){

						parallax(0, $imgs, $fCarousel[i]);

					}, 1000);

				}
			}
		});

		flickFix($fCarousel[i])

		$fCarousel[i].on( 'scroll', function( event, progress ) {

			parallax(progress, $imgs, $fCarousel[i])

		});

	})

	function parallax(progress, cover, e){

		e.slides.forEach( function( slide, i ) {

			var x = ( slide.target + e.x ) * (0.1);

			cover.eq(i).each(function(){

				var img = $(this).find('i');

				img.each( function( slide, i ) {
					i.style.transform = 'translateX( ' + x + 'px)';
				})


			})

		});

	}

	if (!isMobile) {

		$('.featured_store').each(function(i,element) {

			let tl = new gsap.timeline({paused:true}),
				$this = $(this),
				p = $this.find('p');

			tl.from(p, 0.5, { y: '100%', ease: 'power3.out', stagger: 0.1 })

			element.animation = tl;

		})

		$('.featured_store').bind('mouseenter', function(){

			this.animation.play();

		}).mouseleave(function(){

			this.animation.reverse();

		})

	}

}

function createFilters(){

	$filtersCarousel = new Flickity('.bar_filters', {
		prevNextButtons: false,
		accessibility: true,
		bounce: true,
		pageDots: false,
		percentPosition: true,
		selectedAttraction: 0.2,
		friction: 0.8,
		contain: true,
		cellAlign: pageLang == 'en' ? 'left' : 'right',
		rightToLeft: pageLang == 'ar' ? true : false,
	})

	flickFix($filtersCarousel)

	$('body').click(function(e){

		if(isSearch == true && results == false && sizes.width <= 1200) {

			isSearch = false

			$('.bar').removeClass('opened')

		}


	})

	$('#searchInput').click(function(e){

		e.stopPropagation();

		if(isSearch == false && sizes.width <= 1200) {

			isSearch = true

			$('.bar').addClass('opened');

		}

	})

}

function storesPage(){

	if (window.location.hash) {
		var hash = window.location.hash.substring(1);
		const target = $('.filter[data-id='+hash+']');
		if(target.length != 0) {
			go(target, 0)
		}
	} else {

		$('.filter').eq(0).addClass('active')

	}

	$(document).on('input', '#searchInput', function(e){

		var $this = $(this),
			val = $this.val();

		if(val.length != 0) {

			canFilter = false

			$('.bar_filters').addClass('disabled');

			// gsap.to('.selected', 0.5, { autoAlpha: 0, ease: 'power3.out'})

		} else {

			canFilter = true

			$('.bar_filters').removeClass('disabled');

		}

		$filtersCarousel.select(0, false, true)

		$('.filter').removeClass('active')

		$('.filter').eq(0).addClass('active')

		clearTimeout(window.keys);

		window.keys = setTimeout(function(){

			if(val.length != 0 ) {

				$('.stores_col, .stores_row').removeClass('inArray').show()

				$('.stores_col .store').each(function(){

					let name = $(this).attr('data-title');

					if(name) {

						if(name.toLowerCase().indexOf(val) > -1 || name.indexOf(val) > -1){

							$(this).closest('.stores_col').addClass('inArray').closest('.stores_row').addClass('inArray')

							let pos = $('.stores_section').offset().top - parseFloat($('.stores_col').css('margin-top'));

							if(!isMobile) {

								scroll.scrollTo(pos, { duration: 0.3 })
							}

						}
					}

				})

				$('.stores_col:not(.inArray)').add('.stores_row:not(.inArray)').hide()

				results = true

			} else {

				results = false

				$('.stores_row, .stores_col, .stores_col .store').stop().show()

				gsap.to('.selected', 0.5, { autoAlpha: 1, ease: 'power3.out'})

				$fCarousel[i].resize();
			}

			scroll.update()

			pageScroll(0)

		}, 300);
	})

	$('.filter').click(function(e){

		let $this = $(this);

		go($this, 0.3)

	})

	function go(e, dur){

		if(canFilter) {

			forceClick = true;

			var $this = e,
				id = $this.attr('data-id'),
				index = $this.index(),
				head = $('.stores_head[data-id='+id+']'),
				pos = head.offset().top - ( parseFloat($('.stores_row').eq(3).css('margin-top')) / 2);

			if(isMobile) {
				pos = head.offset().top - 30
			}

			history.replaceState(null, null, ' ');

			current = head.closest('.stores_row');

			$filtersCarousel.select(index, false, dur != 0 ? false : true)

			scroll.scrollTo(pos, { duration: dur, disableLerp: dur != 0 ? false : true, callback: function()
				{
					clearTimeout(window.lerp);

					window.lerp = setTimeout(function(){

						forceClick = false

						scroll.update()

					}, 1000);

				}
			})

			$('.stores_row').removeClass('inview')

			$('.filter').removeClass('active')

			current.addClass('inview')

			$this.addClass('active')

		}
	}

}


function FAQs(){

	var faqTL;

	$('.faq_head').click(function(){

		var box = $(this).closest('.faq_block')

		if(!box.hasClass('active')) {

			$('.faq_block').removeClass('active')

			box.addClass('active')

		} else {

			$('.faq_block').removeClass('active')
		}

		if(faqTL) {faqTL.kill()}

		faqTL = new gsap.timeline()

		faqTL

		.call(function(){

			$('.faq_block.active .faq_body').stop().slideDown(200)

			$('.faq_block:not(.active) .faq_body').stop().slideUp(200)

		})

		.to('.faq_block.active .faq_content', 0.5, {autoAlpha: 1, ease: 'power3.Out'}, 0.2)

		.to('.faq_block:not(.active) .faq_content', 0.5, {autoAlpha: 0, ease: 'power3.Out'}, 0.2)

		.call(function(){

			if(asideScroll) { asideScroll.update() }
			if(scroll) { scroll.update() }

		})

	})

}

function appPage(){

	ScrollTrigger.scrollerProxy("#scroller", {
		scrollTop(value) {
			return arguments.length ? scroll.scrollTo(value, 0, 0) : scroll.scroll.instance.scroll.y
		},
		getBoundingClientRect() {
			return {top: 0, left: 0, width: sizes.width, height: sizes.innerHeight}
		},
		pinType: document.querySelector("#scroller").style.transform ? "transform" : "fixed"
	});

	ScrollTrigger.addEventListener("refresh", () => scroll.update())

	ScrollTrigger.refresh();

	anim = new gsap.timeline()

	anim

	.to($(".stagger"), {webkitClipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', stagger: 1})

	createTrigger();


}

function createTrigger(){

	if(anim) {

		if(ST) {ST.kill()}

		ST = ScrollTrigger.create({
			scroller: "#scroller",
			animation: anim,
			invalidateOnRefresh: true,
			scrub: 1,
			start: "+=" + $('.app_section').offset().top,
			end: "+=" + ($('.app_section').innerHeight() * 0.7),
			ease: "none"
		})

	}

}

function farahPage(){

	createTrigger2();

}

function createTrigger2(){

	if(smoother) {
		smoother.scrollTop(0, false)
	}

	let Alltrigger = ScrollTrigger.getAll()
	for (let i = 0; i < Alltrigger.length; i++) {
		Alltrigger[i].kill(true)
	}

	let getCards = document.querySelectorAll('.f_card');
	let screens = document.querySelectorAll('.app_phone_screen i');
	let app = document.querySelector('#farahPhone .app_phone');
	let xVal

	pageLang == 'ar' ? xVal = '50%' : xVal = '-50%'

	getCards.forEach(function(e){
		e.removeAttribute('style')
	})

	screens.forEach(function(e){
		// e.removeAttribute('style')
	})

	app.removeAttribute('style')

	gsap.set(cards, { x: 0 })

	ScrollTrigger.matchMedia({
		
		"(min-width: 768px)": function() {

			gsap.timeline({
				scrollTrigger: {
					invalidateOnRefresh: true,
					trigger: farahPhone,
					start: '0% 0%',
					endTrigger: '#end',
					end: 'bottom bottom',
					pin: true,
					pinSpacing: false,
					scrub: 0.3
				},
				defaults:{ ease:'none', duration: 7}
			})

			.to(screen1, 1, {opacity: 1}, 6)
			
			.to('#farahPhone .app_phone', 1, {
				left: function(){ return sizes.width > 1200 ? '50%' : '48%' },
				right: function(){ return sizes.width > 1200 ? '50%' : '48%' },
				x: function(){
					return sizes.width > 1200 ? xVal : 0
				}
			}, 6)

			gsap.timeline({
				scrollTrigger: {
					invalidateOnRefresh: true,
					trigger: farahContent,
					start: '0% 0%',
					endTrigger: farahWrap,
					end: 'bottom bottom',
					pin: true,
					pinSpacing: false,
					scrub: 0.3
				},
				defaults:{ ease:'none', duration: 7}
			})

			.to(cards, 6, {x: function(){
				return pageLang == 'en' ? - cards.offsetWidth + getCards[0].offsetWidth : cards.offsetWidth - getCards[0].offsetWidth
			}}, 0)

			.to([card1, screen1], 0.5, {opacity: 0}, 0)

			.to(card2, 1, {opacity: 1}, 0)

			.to([card2, screen2], 0.5, {opacity: 0}, 1)

			.to(card3, 1, {opacity: 1}, 0.5)

			.to([card3, screen3], 0.5, {opacity: 0}, 2)

			.to(card4, 1, {opacity: 1}, 1.5)

			.set(screen5, {opacity: 1}, 3)

			.fromTo(screen5, 1, {y: '100%'}, {y: 0}, 3)

			.to(card4, 0.5, {opacity: 0}, 3)

			.to(card5, 1, {opacity: 1}, 2.5)

			.to([card5, screen4], 0.5, {opacity: 0}, 4.5)

			.to(screen5, 0.5, {y: '100%'}, 4.5)

			.to(card6, 1, {opacity: 1}, 3.5)

			.to(card6, 0.5, {opacity: 0}, 5)

			.to(card7, 1, {opacity: 1}, 4.5)

			.to(screen6, 0.5, {opacity: 0}, 5)

		}

	})

}






















